import 'bootstrap/dist/css/bootstrap.css'

import React from 'react';
import {
    Row, Col, Container,
} from 'reactstrap';

import '../icofont.min.css';

// Web theme color root css
import '../components/colors.css';

import '../Terms.css';
import Tdsheader from "../components/Tdsheader";

import '../App.css';
import '../responsive.css';
import Tdscontact from "../components/Tdscontact";
import Tdsfooter from "../components/Tdsfooter";

export default function Agreements() {
    return (
        <div className={"App"}>
            {/* Navbar  */}
            <Tdsheader active={"agreements"} isHome={false} />

            <div className={"section"} id={"agreements"}>
                <h1 className={"heading mt-5 mb-1 ml-3 ml-sm-5"}>Agreements</h1>
                <br/><br/>

                <Container>
                    <Row id={"agreement-maintenance"}>
                        <Col>
                            <h4>Maintenance Contract and Agreement</h4>
                            <h6>A) Periodic Test includes</h6>
                            <p>
                                <ul>
                                    <li>A1) Estimating jack lifespan and consequently estimating the need to replace rubber parts</li>
                                    <li>A2) Checking the appearance form of moving metal parts, joints and jack levers</li>
                                    <li>A3) Checking the quality and essential volume of the jack hydraulic oil</li>
                                    <li>A4) Checking possible oil leakage</li>
                                    <li>A5) Checking the performance without applying pressure</li>
                                    <li>A6) Checking the performance under pressure</li>
                                </ul>
                                <ul>
                                    <li className={"text-info"}>Notice (1): The under pressure checking plans based on tonnage which meets the normal needs of users and is not based on nominal jack capacity.</li>
                                    <li className={"text-info"}>Notice (2): Although these tests do not include jack fixtures, it is essential that users receive the necessary advice on how to correct them and other precautions.</li>
                                </ul>
                            </p>
                            <br/>
                            <h6>B) Maintenance services include</h6>
                            <p>
                                Opals’ agents, while forming a file and assigning a unique code to each of the units under supervision,
                                after the first visit and based on the nature and volume of the application of each jack, perform the following actions:
                                <br/><br/>
                                <ul>
                                    <li>B-1) Determining the review and control periods of the unit which are determined between three or six months, depending on the volume of consumption.</li>
                                    <li>B-2) Records of inspection and any correction or repair operations are stored in the unit file.</li>
                                    <li>B-3) after the end of each review session, users will receive a written report of the accomplished review via their email.</li>
                                    <li>B-4) if the control checklist is passed, the company label will be installed on the body of the unit which contains the unit code, service date and next inspection deadline.</li>
                                    <li>B-5) if the need for repairs is detected, the defective unit, upon the issued receipt, will be taken delivered and returned to users after repairs.</li>
                                    <li>B-6) Comment on the expiration of unsafe units that do not meet the repair requirements.</li>
                                    <li>B-7) if the users declare a need, the company may provide a suitable alternative on loan to users during the repairs of each unit.</li>
                                    <li>B-8) Users of the contracting party enjoy a discount on the rate of necessary repairs.</li>
                                    <li>B-9) the experts of this company provide the necessary technical and safety recommendations to the users in order to extend the lifespan of each unit and improve its safety coefficients.</li>
                                    <li>B-10) the company's message department, through SMS or e-mail of users, provides the necessary notices and coordination for review meetings and possibly performs the necessary coordination to determine the time and place of the visit or the collection and return of the repair units.</li>
                                    <li>B-11) Users of the contracting party, upon their request, can also enjoy some periodic and extraordinary services of this company, including the replacement of used units with new ones in terms of commercial price and without taking into account the profit of the importer and seller.</li>
                                </ul>
                            </p>
                            <br/>
                            <h6>Warranty</h6>
                            <p>
                                <ul>
                                    <li>1: Restored equipment listed as attachment, has three-month warranty. The warranty will be offered just for workmanship, therefore, in case of the same previous defect, Opal, is required to repair them free of charge.</li>
                                    <li>2: Opal is not responsible for the technique and quality of initial manufacturing and production of the units that repairs or monitors includes: Material analyses that used in their parts, machining process quality, dimensions, Thicknesses and other crucial technical specification of assembled parts.</li>
                                    <li>3: Every damages resulting from improper operation do not include warranty.</li>
                                    <li>
                                        4: Users are required to use hydraulic equipment in a principled manner so any incorrect use that is contrary to the instructions of the manufacturers of repaired equipment subject to this contract and warning below, completely is on the customer and customer is responsible for any accident or life or financial losses caused by customer’s negligence.

                                        <ul>
                                            <li>4-1: <strong className={"text-danger"}>SAFETY WARNING:</strong> It is extremely dangerous for any user to work or place different parts of their body under or between heavy objects that have been moved or held high by hydraulic equipment, therefore, after using the hydraulic equipment, users are obliged to completely fill the created safe distance by installing a wedge or objects that have a constant strength, release the Jack or lift and then start working.</li>
                                            <li>4-2: <strong className={"text-danger"}>SAFETY WARNING:</strong> Imposing a load in excess of the nominal capacity (by calculating the torque in lever jacks) and any carelessness in proper placing the arms or fixtures of the hydraulic equipment are extremely dangerous and accidental.
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </p>
                            <br/>
                            <h6>Other consideration</h6>
                           <p>
                               <ul>
                                   <li>1: In the event of major damage to internal components (which will be recognisable after setting down the defective unit) or failure to supply replacement parts, Opal will not be responsible for repairing the above equipment.</li>
                                   <li>1-1: In such cases, Opal is required to return non-repairable equipment free of charge.</li>
                                   <li>2: After setting down each of the equipment subject to this contract, if it turns out that there is a need to spend more than the given quote, the information will be made to inform the customer and continuation of repairs is subject to the customer’s agreement with the new quote.</li>
                               </ul>
                           </p>
                            <br/>
                            <h5>Contract Fees</h5>
                            <h6>1) Test fees</h6>
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={8}>Single session testing include max 5 units (Except for Hoists):</Col>
                                <Col xs={2} lg={4}>$100.00</Col>
                                <Col xs={12}>
                                    <p className={"text-danger"}>(Notice: For each unit in addition to the specified number, we receive $10 in excess of the above rates.)</p>
                                </Col>
                            </Row>
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={8}>Next sessions:</Col>
                                <Col xs={2} lg={4}>$70.00</Col>
                            </Row>
                            <br/>
                            <h6>2) Annual maintenance</h6>
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={8}>First session of one year and above support (Max 6 unit):</Col>
                                <Col xs={2} lg={4}>$100.00</Col>
                            </Row>
                            <Row className={"mb-2"}>
                                <Col xs={10} lg={8}>Monthly fee per unit about two to four inspections and tests in year include cost of essential repairs:</Col>
                                <Col xs={2} lg={4}>$4.99</Col>
                            </Row>
                            <br/>
                            <p>
                                <ul>
                                    <li className={"text-info"}>Notice 1: At the first inspection, if each unit is defective, the cost of the repair will be calculated separately from the maintenance contract, according to the attached table.</li>
                                    <li className={"text-info"}>Notice 2: The repair fee for the units mentioned in the contract will include a 20% discount.</li>
                                    <li className={"text-info"}>Notice 3: The possible cost of purchasing spare parts for each unit is separate from the repair fee.</li>
                                    <li className={"text-info"}>Notice 4: Maintenance and periodic inspections of car lifters (Hoists), only included their cylinders and monthly fee is calculated per each cylinders.</li>
                                </ul>
                            </p>
                            <br/>
                            <h6>Duration of the Maintenance contract</h6>
                            <p>The duration of the maintenance contract is signed for a year and will be renewable.</p>
                            <br/>
                            <h6>Other considerations</h6>
                            <p>
                                <ul>
                                    <li>1: Because it is not possible to estimate the repair process time before setting down each of the defective units, completion of the present agreement will be based on the announcement of the Opal.</li>
                                    <li>2: Opal’s agents are responsible for the collection and delivery of agreement items.</li>
                                    <li>3: up to radius of ten kilometres to collect and return repaired units, shipping will be free.</li>
                                </ul>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Contact Us Section Start  */}
            <div className="section contactus grapping" id="contact">
                <Tdscontact />
            </div>

            {/* Footer  Section Start */}
            <div className="">
                <Tdsfooter/>
            </div>
        </div>
    );
}